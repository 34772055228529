



































import { Vue, Component } from "vue-property-decorator";
import { api_service } from "@/api";
import Inner from "@/components/inner/index.vue";
import { OtherModule } from "@/store/other";
type route_type = "content" | "use" | "privacy";

@Component({
  components: {
    Inner,
  },
})
export default class extends Vue {
  info = {
    content: "",
    use: "",
    privacy: "",
  };

  get type(): route_type {
    if (["content", "use", "privacy"].includes(this.$route.params.type)) {
      return this.$route.params.type as route_type;
    }
    if (this.$route.name === "service") {
      this.$router.replace("/service/content");
    }
    return "content";
  }

  select(router: route_type) {
    this.$router.push(`/service/${router}`);
  }

  get inner() {
    return this.info[this.type];
  }

  async get_info() {
    this._loading = true;
    this.info = await api_service.get_all().finally(() => {
      this._loading = false;
    });
  }

  created() {
    this.get_info();
  }

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
}
